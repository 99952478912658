import React from "react";
import axios from "axios";

const PaymentButton = () => {
  const handlePayment = async () => {
    try {
      const response = await axios.post("/api/create-payment", {
        amount: "1000.00", // сумма в рублях
        description: "Test payment",
      });

      const { confirmation } = response.data;
      window.location.href = confirmation.confirmation_url;
    } catch (error) {
      console.error("Payment error", error);
    }
  };

  return <button onClick={handlePayment}>Оплатить</button>;
};

export default PaymentButton;
